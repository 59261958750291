var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            _vm._l(_vm.notificationSets, function(notificationSet) {
              return _c(
                "v-col",
                { key: notificationSet.name, attrs: { cols: "4" } },
                [
                  _c("h3", [
                    _vm._v(
                      "\n          " +
                        _vm._s(notificationSet.name) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-list",
                    _vm._l(notificationSet.notifications, function(
                      notification
                    ) {
                      return _c(
                        "v-tooltip",
                        {
                          key: notification.day,
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: notification.color
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sendNotification(
                                                  notification.route
                                                )
                                              }
                                            }
                                          },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(notification.name))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(notification.tooltip))])]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }