<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col
          v-for="notificationSet in notificationSets"
          :key="notificationSet.name"
          cols="4"
        >
          <h3>
            {{ notificationSet.name }}
          </h3>
          <v-list>
            <v-tooltip
              right
              v-for="notification in notificationSet.notifications"
              :key="notification.day"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  @click="sendNotification(notification.route)"
                  :color="notification.color"
                >
                  <v-list-item-title>{{ notification.name }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ notification.tooltip }}</span>
            </v-tooltip>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'NotificationsDialog',
  props: ['project'],
  data() {
    return {
      loading: false,
      notificationSets: [
        {
          name: 'Onboarding',
          color: 'primary',
          notifications: [
            {
              ID: 0,
              color: 'red',
              name: 'Velkommen',
              route: '/notifications/welcomeMessage/' + this.project.customerUID,
              tooltip: 'Allerede sendt automatisk i det kunden registrerte seg.',
            },
          ],
        },
        {
          name: 'Ikke svar',
          color: 'primary',
          notifications: [
            {
              ID: 1,
              color: 'orange',
              name: 'Dag 1',
              route: '/notifications/noAnswer/1/' + this.project.ID,
              tooltip: 'Send hvis du ikke får kontakt med kunden dagen etter at kunden registrerte seg.',
            },
            {
              ID: 2,
              color: 'orange',
              name: 'Dag 2',
              route: '/notifications/noAnswer/2/' + this.project.ID,
              tooltip: 'Send hvis du ikke får kontakt med kunden to dager etter at kunden registrerte seg.',
            },
            {
              ID: 3,
              color: 'orange',
              name: 'Dag 3',
              route: '/notifications/noAnswer/3/' + this.project.ID,
              tooltip: 'Send hvis du ikke får kontakt med kunden tre dager etter at kunden registrerte seg.',
            },
            {
              ID: 10,
              color: 'orange',
              name: 'Dag 10',
              route: '/notifications/noAnswer/10/' + this.project.ID,
              tooltip: 'Send hvis du ikke får kontakt med kunden ti dager etter at kunden registrerte seg.',
            },
          ],
        },
      ],
    };
  },
  methods: {
    async sendNotification(route) {
      await this.$axios.post(route);
      await this.$axios.patch('/users/incrementNumberOfTimesContactedToday/' + this.project.customerUID);
      this.$store.dispatch('displayAlert', {
        color: 'green',
        message: 'Meldingen ble sendt på epost og SMS.',
      });
      this.$emit('contacted');
    },
  },
};
</script>
